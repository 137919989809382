.leaflet-pm-toolbar {
}

.leaflet-pm-toolbar .leaflet-buttons-control-button {
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
}

.leaflet-pm-toolbar
  .leaflet-pm-actions-container
  a.leaflet-pm-action:first-child:not(.pos-right),
.leaflet-pm-toolbar
  .leaflet-pm-actions-container
  a.leaflet-pm-action:last-child.pos-right {
  border-radius: 0;
}

.leaflet-pm-toolbar .button-container a.leaflet-buttons-control-button {
  border-radius: 0;
}

.leaflet-pm-toolbar
  .button-container:last-child
  a.leaflet-buttons-control-button {
  border-radius: 0 0 2px 2px;
}

.leaflet-pm-toolbar
  .button-container:first-child
  a.leaflet-buttons-control-button {
  border-radius: 2px 2px 0 0;
}

.leaflet-pm-toolbar
  .button-container:last-child
  a.leaflet-buttons-control-button {
  border-bottom: none;
}

.leaflet-pm-toolbar .control-fa-icon {
  font-size: 19px;
  line-height: 24px;
}

.leaflet-pm-toolbar .control-icon {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.leaflet-pm-toolbar .leaflet-pm-icon-marker {
  background-image: url('../assets/icons/Marker.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-polygon {
  background-image: url('../assets/icons/Polygon.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-polyline {
  background-image: url('../assets/icons/Line.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-circle {
  background-image: url('../assets/icons/Circle.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-circle-marker {
  background-image: url('../assets/icons/CircleMarker.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-rectangle {
  background-image: url('../assets/icons/Rectangle.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-delete {
  background-image: url('../assets/icons/Eraser.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-edit {
  background-image: url('../assets/icons/Edit_Vertex.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-drag {
  background-image: url('../assets/icons/Move.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-cut {
  background-image: url('../assets/icons/Scissors.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-snapping {
  background-image: url('../assets/icons/Magnet.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-rotate {
  background-image: url('../assets/icons/Rotate.svg');
}
.leaflet-pm-toolbar .leaflet-pm-icon-text {
  background-image: url('../assets/icons/Text.svg');
}

.leaflet-buttons-control-button:hover,
.leaflet-buttons-control-button:focus {
  cursor: pointer;
  background-color: #f4f4f4;
}
.active > .leaflet-buttons-control-button {
  box-shadow: inset 0 -1px 5px 2px rgba(81, 77, 77, 0.31);
}

.leaflet-buttons-control-text-hide {
  display: none;
}

.button-container {
  position: relative;
}

.button-container .leaflet-pm-actions-container {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 100%;
  display: none;
  white-space: nowrap;
  direction: ltr;
}

.leaflet-right
  .leaflet-pm-toolbar
  .button-container
  .leaflet-pm-actions-container {
  right: 100%;
  left: auto;
}

.button-container.active .leaflet-pm-actions-container {
  display: block;
}

.button-container
  .leaflet-pm-actions-container:not(.pos-right)
  a.leaflet-pm-action:last-child {
  border-radius: 0 3px 3px 0;
  border-right: 0;
}
.button-container
  .leaflet-pm-actions-container.pos-right
  a.leaflet-pm-action:first-child {
  border-radius: 3px 0 0 3px;
}
.button-container
  .leaflet-pm-actions-container.pos-right
  a.leaflet-pm-action:last-child {
  border-right: 0;
}
.button-container .leaflet-pm-actions-container .leaflet-pm-action {
  padding: 0 10px;
  background-color: #666;
  color: #fff;
  display: inline-block;
  width: auto;
  border-right: 1px solid #eee;
  user-select: none;
  border-bottom: none;
  height: 29px;
  line-height: 29px;
  vertical-align: middle;
}
.leaflet-pm-toolbar
  .button-container:first-child.pos-right.active
  a.leaflet-buttons-control-button {
  border-top-left-radius: 0;
}
.leaflet-pm-toolbar
  .button-container:first-child.active:not(.pos-right)
  a.leaflet-buttons-control-button {
  border-top-right-radius: 0;
}

.button-container .leaflet-pm-actions-container .leaflet-pm-action:hover,
.button-container .leaflet-pm-actions-container .leaflet-pm-action:focus {
  cursor: pointer;
  background-color: #777;
}

/* That the active control is always over the other controls */
.leaflet-pm-toolbar.activeChild {
  z-index: 801;
}

.leaflet-buttons-control-button.pm-disabled {
  background-color: #f4f4f4;
}

.leaflet-buttons-control-button.pm-disabled > .control-icon {
  filter: opacity(0.6);
}
